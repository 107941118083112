(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('text-content', {
            parent: 'entity',
            url: '/text-content',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'TextContents'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/text-content/text-contents.html',
                    controller: 'TextContentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('text-content-detail', {
            parent: 'entity',
            url: '/text-content/{textLocation}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'TextContent'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/text-content/text-content-detail.html',
                    controller: 'TextContentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TextContent', function($stateParams, TextContent) {
                    return TextContent.get({textLocation : $stateParams.textLocation}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'text-content',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('text-content-detail.edit', {
            parent: 'text-content-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/text-content/text-content-dialog.html',
                    controller: 'TextContentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TextContent', function(TextContent) {
                            return TextContent.get({textLocation : $stateParams.textLocation}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('text-content.new', {
            parent: 'text-content',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/text-content/text-content-dialog.html',
                    controller: 'TextContentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                textLocation: null,
                                textContent: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('text-content', null, { reload: 'text-content' });
                }, function() {
                    $state.go('text-content');
                });
            }]
        })
        .state('text-content.edit', {
            parent: 'text-content',
            url: '/{textLocation}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/text-content/text-content-dialog.html',
                    controller: 'TextContentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TextContent', function(TextContent) {
                            return TextContent.get({textLocation : $stateParams.textLocation}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('text-content', null, { reload: 'text-content' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('text-content.delete', {
            parent: 'text-content',
            url: '/{textLocation}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/text-content/text-content-delete-dialog.html',
                    controller: 'TextContentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TextContent', function(TextContent) {
                            return TextContent.get({textLocation : $stateParams.textLocation}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('text-content', null, { reload: 'text-content' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
