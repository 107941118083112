(function() {
    'use strict';
    angular
        .module('clarktraceyApp')
        .factory('Review', Review)
        .factory('ReviewByProduct', ReviewByProduct);

    Review.$inject = ['$resource'];
    ReviewByProduct.$inject = ['$resource'];

    function Review ($resource) {
        var resourceUrl =  'api/reviews/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ReviewByProduct ($resource) {
        var resourceUrl =  'api/reviewsByProduct/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
