(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('MediaGroupDialogController', MediaGroupDialogController);

    MediaGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MediaGroup'];

    function MediaGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MediaGroup) {
        var vm = this;

        vm.mediaGroup = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mediaGroup.id !== null) {
                MediaGroup.update(vm.mediaGroup, onSaveSuccess, onSaveError);
            } else {
                MediaGroup.save(vm.mediaGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('clarktraceyApp:mediaGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
