(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'Event', 'TextContent'];

    function HomeController ($scope, Principal, LoginService, Event, TextContent) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.noEvents = false;


        // Populate CMS Front Page Text Content
        //vm.homePageTextContent = null;

        TextContent.get({textLocation : "FRONT_PAGE"},
            onTextContentSuccess)
        function onTextContentSuccess(data) {
            vm.homePageTextContent = data;
        }

        // Populate 8 next events
        vm.events = [];
        Event.query({

        }, onEventSuccess, onEventError)
        function onEventSuccess(data) {
            vm.events = data.slice(0,8);
        }
        function onEventError(error) {
            vm.noEvents = true;
        }


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
    }
})();
