(function() {
    'use strict';

    angular
        .module('clarktraceyApp')

        .filter('trustAsResourceUrl', ['$sce', function($sce) {
            return function(val) {
                return $sce.trustAsResourceUrl(val);
            };
        }])

        .controller('MediaController', MediaController);

    MediaController.$inject = ['$scope', '$state', 'MediaGroup', 'Principal'];

    function MediaController ($scope, $state, MediaGroup, Principal) {
        var vm = this;

        vm.mediaGroups = [];

        loadAll();

        isAuthenticated();

        function isAuthenticated() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function loadAll() {
            MediaGroup.query(function(result) {
                vm.mediaGroups = result;
                vm.searchQuery = null;
            });
        }
    }
})();
