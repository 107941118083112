(function() {
    'use strict';
    angular
        .module('clarktraceyApp')
        .factory('Mail', Mail);

    Mail.$inject = ['$resource'];

    function Mail ($resource) {
        var resourceUrl =  'api/mail';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });
    }
})();
