(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', 'Mail'];

    function ContactController ($scope, Mail) {
        var vm = this;

        vm.sendMail = sendMail;

        function sendMail () {
            Mail.save(vm.mail);
        }
    }
})();
