(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('MediaGroupDetailController', MediaGroupDetailController);

    MediaGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MediaGroup'];

    function MediaGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, MediaGroup) {
        var vm = this;

        vm.mediaGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('clarktraceyApp:mediaGroupUpdate', function(event, result) {
            vm.mediaGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
