(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Product', 'ReviewByProduct', 'Principal'];

    function ProductDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Product, ReviewByProduct, Principal) {
        var vm = this;

        vm.product = entity;
        vm.reviews = ReviewByProduct.get({id: vm.product.id});
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.otherProducts = null;

        populateOtherProducts();
        isAuthenticated();

        // Populate Other Products
        function populateOtherProducts() {

            var otherProducts = Product.query();
            otherProducts.$promise.then(function (result) {

                // Remove current product from otherProducts array
                for (var i = 0; i < result.length - 1; i++) {
                    if (parseInt(result[i].id) == $stateParams.id) {
                        console.log("True");
                        result.splice(i, 1);
                        vm.otherProducts = result;
                        break;
                    }
                }
            });
        }

        function isAuthenticated() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        var unsubscribe = $rootScope.$on('clarktraceyApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);

        // Split String tracklist into separate items - shoudl be simple csv
        vm.trackList = vm.product.tracks.split(',');
        vm.personnel = vm.product.personnel.split(',');
    }
})();
