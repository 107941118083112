(function() {
    'use strict';

    angular
        .module('clarktraceyApp')
        .controller('TextContentController', TextContentController);

    TextContentController.$inject = ['$scope', '$state', 'TextContent'];

    function TextContentController ($scope, $state, TextContent) {
        var vm = this;

        vm.textContents = [];

        loadAll();

        function loadAll() {
            TextContent.query(function(result) {
                vm.textContents = result;
                vm.searchQuery = null;
            });
        }
    }
})();
